.bg-login-image {
    background: url('../shared/login_logo.svg');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}

.login-card {
    display: flex;
    min-height: 500px;
    justify-content: center;
    flex-direction: column;
}