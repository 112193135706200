@import url("https://fonts.googleapis.com/css?family=Nunito:200,200i,300,300i,400,400i,600,600i,700,700i,800,800i,900,900i");

#root {
  min-height: 100vh;
}

#root>section {
  min-height: 100vh;
  background: linear-gradient(180deg, rgba(128, 126, 126, 0.767) 10%, #8c9b9cc7 25%, #8eb8bd 40%, #92d1d8 80%, #93DCE3 100%);
}

body {
  height: 100%;
  margin: 0;
  font-family: Nunito, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol", "Noto Color Emoji" !important;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;

  overflow-x: hidden;
  overflow-y: auto;
  background-color: var(--surface-c);
  font-weight: 400;
  color: var(--text-color);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.deleteButtonGlobal{
margin-left: 73% !important; 
}

.pointer {
  cursor: pointer;
}

.p-datatable .p-datatable-tbody>tr>td {
  text-align: left !important;
  padding-left: 0.7rem;
}


.flex11 {
  flex: 1 1;
}

a {
  color: #282c2d !important;
  ;
}

a:hover {
  color: #282c2d !important;
}

#content-wrapper {
  background-color: var(--surface-a);

  flex: 1;
  min-height: 100vh;
}

#content-wrapper>div {
  flex: 1;
  /* padding: 0 0.5rem; */
}

.hidden {
  display: none !important;
}

.shadow {
  box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15) !important;
}

.align-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.bg-gradient-primary {
  background-color: #93DCE3;
  background-image: linear-gradient(180deg, rgba(128, 126, 126, 0.767) 10%, #8c9b9cc7 25%, #8eb8bd 40%, #92d1d8 80%, #93DCE3 100%);
  background-size: cover;
}

img.img-fluid.p-d-block.p-m-auto.mb-3 {
  padding-top: 2rem;
  padding-left: 1rem;
}

.p-card.p-o-hidden.p-shadow-22.my-5 {
  background-color: white;
}


.p-card .p-card-content {
  padding: 0 !important;
}

.page-header {
  font-weight: bold;
  text-transform: uppercase;
  color: #858796;
  font-size: 1.5rem;
  margin: 0;
}

a .nav-title {
  color: #fff;
  font-size: 14px;
}

text-light.flex.align-center.active :hover {
  background: #282c2d !important;
}


.sidebarSection {
  justify-content: unset;
  padding-left: 18px !important;
}

#sidebar.bg-gradient-primary.sidebar {
  position: sticky !important;
  top: 0 !important;
  max-height: 103vh;
}

.error {
  color: #5a5c69;
  font-size: 7rem;
  position: relative;
  line-height: 1;
  width: 12.5rem;
}

.text-light.flex.align-center.active {
  background: #282c2d !important;
  background: #282c2d85 !important;
}

.error:after {
  content: attr(data-text);
  position: absolute;
  left: 2px;
  text-shadow: -1px 0 #e74a3b;
  top: 0;
  color: #5a5c69;
  background: #f8f9fc;
  overflow: hidden;
  clip: rect(0, 900px, 0, 0);
  animation: noise-anim 2s infinite linear alternate-reverse;
}

.error:before {
  content: attr(data-text);
  position: absolute;
  left: -2px;
  text-shadow: 1px 0 #4e73df;
  top: 0;
  color: #5a5c69;
  background: #f8f9fc;
  overflow: hidden;
  clip: rect(0, 900px, 0, 0);
  animation: noise-anim-2 3s infinite linear alternate-reverse;
}

.w100 {
  width: 100%;
}

.h100 {
  height: 100%;
}

.pw input {
  width: 100%;
}

.text-left {
  text-align: left;
}

.p-card-header {
  padding: 1rem 1rem 0rem 1rem;
  font-size: 1.3rem;
}

.p-card-footer {
  padding: 0rem 1rem 1rem 1rem !important;
}

.sidebarSection a.active {
  background: #282c2d !important;
}

#content-wrapper .p-tabview .p-tabview-panels,
#content-wrapper .p-tabview .p-tabview-panels>div,
#content-wrapper .p-tabview .p-tabview-panels .p-dataview .p-dataview-content {
  display: flex;
  flex-direction: column;
  flex: 1 1;
}

@media only screen and (min-width: 576px) {
  .p-container {
    width: 100%;
  }
}

@media only screen and (min-width: 768px) {
  .p-container {
    width: 100%;
  }
}

@media only screen and (min-width: 992px) {
  .p-container {
    width: 48em;
  }
}

@media only screen and (min-width: 1200px) {
  .p-container {
    width: 65em;
  }
}

.p-container {
  margin: auto;
}

.p-o-hidden {
  overflow: hidden;
}

.w100 {
  width: 100%;
}

.spaced {
  margin-right: 8px !important;
}

@-webkit-keyframes noise-anim {
  0% {
    clip: rect(49px, 9999px, 40px, 0);
  }

  5% {
    clip: rect(75px, 9999px, 72px, 0);
  }

  10% {
    clip: rect(97px, 9999px, 93px, 0);
  }

  15% {
    clip: rect(15px, 9999px, 9px, 0);
  }

  20% {
    clip: rect(14px, 9999px, 92px, 0);
  }

  25% {
    clip: rect(18px, 9999px, 94px, 0);
  }

  30% {
    clip: rect(17px, 9999px, 20px, 0);
  }

  35% {
    clip: rect(71px, 9999px, 59px, 0);
  }

  40% {
    clip: rect(42px, 9999px, 84px, 0);
  }

  45% {
    clip: rect(56px, 9999px, 25px, 0);
  }

  50% {
    clip: rect(46px, 9999px, 14px, 0);
  }

  55% {
    clip: rect(47px, 9999px, 1px, 0);
  }

  60% {
    clip: rect(64px, 9999px, 58px, 0);
  }

  65% {
    clip: rect(89px, 9999px, 92px, 0);
  }

  70% {
    clip: rect(56px, 9999px, 39px, 0);
  }

  75% {
    clip: rect(80px, 9999px, 71px, 0);
  }

  80% {
    clip: rect(8px, 9999px, 13px, 0);
  }

  85% {
    clip: rect(66px, 9999px, 68px, 0);
  }

  90% {
    clip: rect(68px, 9999px, 4px, 0);
  }

  95% {
    clip: rect(56px, 9999px, 14px, 0);
  }

  100% {
    clip: rect(28px, 9999px, 53px, 0);
  }
}

@keyframes noise-anim {
  0% {
    clip: rect(49px, 9999px, 40px, 0);
  }

  5% {
    clip: rect(75px, 9999px, 72px, 0);
  }

  10% {
    clip: rect(97px, 9999px, 93px, 0);
  }

  15% {
    clip: rect(15px, 9999px, 9px, 0);
  }

  20% {
    clip: rect(14px, 9999px, 92px, 0);
  }

  25% {
    clip: rect(18px, 9999px, 94px, 0);
  }

  30% {
    clip: rect(17px, 9999px, 20px, 0);
  }

  35% {
    clip: rect(71px, 9999px, 59px, 0);
  }

  40% {
    clip: rect(42px, 9999px, 84px, 0);
  }

  45% {
    clip: rect(56px, 9999px, 25px, 0);
  }

  50% {
    clip: rect(46px, 9999px, 14px, 0);
  }

  55% {
    clip: rect(47px, 9999px, 1px, 0);
  }

  60% {
    clip: rect(64px, 9999px, 58px, 0);
  }

  65% {
    clip: rect(89px, 9999px, 92px, 0);
  }

  70% {
    clip: rect(56px, 9999px, 39px, 0);
  }

  75% {
    clip: rect(80px, 9999px, 71px, 0);
  }

  80% {
    clip: rect(8px, 9999px, 13px, 0);
  }

  85% {
    clip: rect(66px, 9999px, 68px, 0);
  }

  90% {
    clip: rect(68px, 9999px, 4px, 0);
  }

  95% {
    clip: rect(56px, 9999px, 14px, 0);
  }

  100% {
    clip: rect(28px, 9999px, 53px, 0);
  }
}