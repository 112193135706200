.navbar {
    padding: 0.5rem;
}

#sidebar {
    margin: 0;
    padding: 0.5rem;
    min-height: 100vh;
    width: 100%;
    position: absolute;
    z-index: 1000;
}

.sidebarSection {
    list-style: none;
    padding: 0;
    margin: 0;
}

#sidebar a {
    background-color: transparent;
    color: #fff;
    display: inline-block;
    width: 100% !important;
    padding: 0.25rem 0.5rem;
    margin-bottom: 0.25rem;
    text-decoration: none !important;
    border-radius: 0.25rem;
    transition: color 0.2s ease-in-out;
    border-radius: 0.5rem;
    transition: background 0.3s ease-in-out;
}

.position-relative {
    position: relative;
}

a .nav-title {
    color: white;
}

.p-navbar {
    list-style: none;
    margin: 0;
    padding: 0;
}

#sidebar hr.sidebar-divider {
    border-top: 1px solid white;
    border-bottom: 0;
}

#sidebar a:hover {
    background: #93DCE3 !important;
}

#sidebar hr.sidebar-divider {
    margin: 0;
}

.sidebar-heading {
    color: white;
    text-transform: uppercase;
    font-weight: bold;
}

.sidebarSection a.active {
    background: #c5429196 !important;
}

.nav-title {
    display: block;
}

.feedback {
    position: fixed;
    right: -43px;
    top: 33%;
    z-index: 1;
    transform: rotate(90deg);

}

.feedback .p-button:enabled:hover,
.p-button:not(button):not(a):not(.p-disabled):hover {
    background: #93DCE3;
    color: #282c2d85;
    border-color: #93DCE3;
}

.overlaypanel-feedback::before,
.overlaypanel-feedback::after {
    border-color: transparent !important;
    border-bottom-color: transparent !important;
}

.overlaypanel-feedback {
    left: auto !important;
    margin-top: -45px !important;
    padding-top: 50px;
}

.overlaypanel-feedback .grid>div {
    border-color: var(--primary-color);
    padding: 15px 0 15px 0;
}

.overlaypanel-feedback .grid:not(.feedbackformgrid)>div:nth-child(1) {
    border-right-style: solid;
    border-right-width: 1px;
    border-bottom-style: solid;
    border-bottom-width: 1px;
}

.overlaypanel-feedback .grid:not(.feedbackformgrid)>div:nth-child(2) {
    border-left-style: solid;
    border-left-width: 1px;
    border-bottom-style: solid;
    border-bottom-width: 1px;
}

.overlaypanel-feedback .grid:not(.feedbackformgrid)>div:nth-child(3) {
    border-right-style: solid;
    border-right-width: 1px;
    border-top-style: solid;
    border-top-width: 1px;
}

.overlaypanel-feedback .grid:not(.feedbackformgrid)>div:nth-child(4) {
    border-left-style: solid;
    border-left-width: 1px;
    border-top-style: solid;
    border-top-width: 1px;
}

.feedbackformgrid>div:nth-child(1) {
    padding-left: 20px;
}

.feedbackformgrid input {
    width: 100%;
}

.feedbackformgrid .p-input-icon-left,
.feedbackformgrid textarea {
    width: 90%;
}

.overlaypanel-feedback .p-overlaypanel-close {
    right: 5px;
    top: 5px;
}

@media only screen and (min-width: 576px) {}

@media only screen and (min-width: 768px) {
    #sidebar.sidebar {
        width: 6.5rem;
        position: unset !important;
    }

    .sidebarSection li a {
        justify-content: center;
        padding: 1rem !important;
        color: white !important;
    }

    .nav-title {
        display: none;
    }
}

@media only screen and (min-width: 992px) {}

@media only screen and (min-width: 1500px) {
    #sidebar {
        width: 15rem !important;
        position: unset !important;
    }

    .nav-title {
        display: block !important;
    }

    .sidebarSection li a {
        justify-content: unset;
        padding: 0.25rem 0.5rem !important;
    }
}